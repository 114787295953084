body {
    margin: 0;
    font-family: 'Exo 2', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 1px;
    background-color: var(--background-dark-grey);
  }
  
  :root {
    --content-container-width: 1070px;
  
    --primary-color-dark: #28242c;
    --primary-color-light: rgb(91, 202, 250);
    --secondary-color-dark: #005F00;
    --secondary-color-light: #4c9a2a;
    --tertiary-color-dark: #617075ff;
    --primary-overlay: #1abc9c75;  
    --slideshow-container-height: 600px;

    --background-medium-grey: #2c2c2c;
    --background-dark-grey: #1E1E1E;
    --background-darker-grey: #0e0e0e;

    --off-white: rgb(216, 216, 216);

  }

  h1, h2, h3, h4, h5, h6, p {
    color: var(--off-white);
    margin: px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  h1 {
    font-weight: 800 !important;
  }

  .logo {
    margin: auto;
    max-height: 120px;
    float: left;
    padding-top: 20px;
    padding-left: 12px;
    padding-bottom: 20px;
  }

  .hero-text {
    color: white;
    width: 558px;
    margin-left: 30px;
  }

  .hero-text h1 {
    font-size: 60px;
    font-weight:100;
    padding-top: 300px;
  }

  .hero-image {
    max-width: 1320px;
    width: 100%;
    margin-top: 50px;
    margin: auto;
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    animation: fade-in 1.8s;
  }

  .hero-text-padding {
    padding-top: 200px!important;
  }

.home-hero-image {
  background-image: url("./images/hero-image.jpg");
}

.classes-hero-image {
  background-image: url("./images/markpads.png");
}

.coaches-hero-image {
  background-image: url("./images/coach_group_bw.jpg");
}

.faqs-hero-image {
  background-image: url("./images/club3_bw.jpg");
}

.justify-content-end {
    justify-content: flex-end!important;
}

.header-container {
  display: block!important;
  background-color: var(--background-darker-grey);
}

.underline{
  border: 1px solid var(--secondary-color-light);
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  width: 50%;
  position: absolute;
  opacity: 100;
}

.short-underline{
  width: 18%;
}

.navbar-expand .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
}


.offcanvas-header {
  background-color: var(--background-medium-grey);
  padding-left: 0px;
}

.navbar {
  background-color: var(--background-darker-grey)!important;
  flex-wrap: wrap!important;
  padding-right: 0;
}

.nav-link {
  color: var(--off-white)!important;
}

.nav-link:hover {
  background-color: var(--secondary-color-light)!important;
}

.navbar-brand {
  margin: 0px;
}

.navbar-toggler{
  margin-top: 35px;
  float: right;
  border-color: none;
}

.navbar-toggler-icon{
  background-image: url("images/hamburger-menu.svg")!important;
}

.btn-close {
  background-image: url("images/close.svg")!important;
  scale: 2;
}

.navbar-togger{
  width: 100%;
}

.coach-profile-pic {
  max-width: 310px;
  padding: 0;
}

.coaches-profile-card{
  max-width: 100%;
  
  padding: 0;
}

.coach-col {
  padding: 0!important;
}

.CardGroup {
    max-width: 1320px;
    width: 100%;
    column-gap: 30px;
}

.card-header{
  padding: 0;
}

.card-block {
  padding-top: 16px;
}


.card {
  background-color: var(--background-medium-grey);
  margin-right: 0px!important;
  margin-left: auto!important;
  margin-right: auto!important;
  
}

.bordered-card {
  border: 2px solid var(--secondary-color-light)!important;
}

.card-body {
  background-color: var(--background-medium-grey);
  color: white;

}

.set-card-height {
  max-height: 240px;
}

.btn-primary {
  background-color: var(--secondary-color-light)!important;
  border-color: var(--secondary-color-light);
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--secondary-color-light)!important;
  border-color: var(--secondary-color-light);
}

.form-label {
  color: var(--off-white);
}

.accordion-item {
  background-color: none!important;
}

.accordion-button {
  background-color: var(--background-medium-grey);
  color: var(--off-white);
}

.accordion-button {
  border-color: none!important;
  box-shadow: none!important;
}

.accordion-button:not(.collapsed) {
  background-color: var(--secondary-color-light);
  color: var(--off-white);
}

.accordion-body{
  background-color: var(--background-medium-grey);
}

.footer-container {
  background-color: var(--background-darker-grey);
  width: 100%;
}

.footer {
  background-color: var(--background-darker-grey);
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-size: 14px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer .footer-left {
  width: 33%;
}

.footer .about {
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 10px;
}

.footer .icons a {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}

.footer-icon {
  width: 2rem;
  height: 2rem;
  color: var(--off-white);
  transition: all 250ms;
  margin-right: 15px;
}

.footer-icon-interactable:hover {
  color: var(--secondary-color-light);
  transform: translateY(-2px);
}

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none; 
}

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  font-size: 28px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.contact-info {
  padding-bottom: 20px;
}

.padding-top {
  padding-top: 50px;
}

.google-map { 
  width: 100%;
  height: 500px;
  border: 0;
  padding-top: 40px;
}

hr {
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 90%;
}

.button {
  background-color: var(--secondary-color-light)!important;
  border-color: var(--secondary-color-light)!important;
  border-radius: 0;
}

.button:hover {
  background-color: var(--secondary-color-dark)!important;
  border-color: var(--secondary-color-dark)!important;
  transition: 0.2s;
}

.content {
  min-height: calc(100vh - 400px);
}

.textarea {
  min-height: 150px;
}

.map-container{
  overflow: hidden; 
  position: relative;
}

.map-div {
  position:relative;
  width:100%;
  padding-top:56.25%;
  overflow:visible;
}

.map-iframe {
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
}

.text-border{
  border: 1px solid black;
  background-color: black;
  color: var(--secondary-color-light)!important;
  width: fit-content;
}

.text-border:hover {
  background-color: var(--secondary-color-light)!important;
  color: black!important;
  transition: 0.2s;
}

.cta-button{
  border: 1px solid var(--secondary-color-light)!important;
  background-color: var(--secondary-color-light)!important;
  padding: 6px;
  width: fit-content;
  color: white!important;
}

.testimonial-bg {
  background-image: url("./images/club_wall_full_bw.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 12px;
  margin-right: 12px;
  padding-right: 0;
  padding-left: 0;
  width: auto;
}

.testimonial-card-group {
  width: 100%;
}

.testimonial-name {
  position: absolute;
  
  right: 20px;
  bottom:0;
}

.testimonial-text {
  text-align: center;
}

.testiomonial-title{
  color: var(--secondary-color-light);
  padding-top: 60px;
}

.speech-mark {
  color: var(--secondary-color-light);
}

.scrollToTop {
   left: 50%;
   bottom: 40px;
}

.goTopHidden{
  display: none;
}

.goTop{
  display: inline-block;
}

.goTop__text{
  position: fixed;
  display: flex;
  justify-content: center;
  padding:6px;
   font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  bottom: 5%;
  left: 90%;
  cursor: pointer;
  text-align: center;
  align-items: center;
}

.goTop__text:hover{
  transform: scale;
}

@media (max-width: 1400px){
      
  .card-body {
    max-height: none;
  }
}

@media (max-width: 991px) {
  .card-group {
    display: block!important;
  }

  .card {
    margin-bottom: 20px!important;
  }
}

@media (max-width: 990px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer .footer-center i {
    margin-left: 0;
  }
  .offcanvas-body {
    background-color: var(--background-medium-grey);
    color: var(--off-white);
  }

  .card{
    display: block;
  }

  .coach-profile-pic{
    min-width: 100%!important;
  }

  .card-body {
    max-height: none;
  }

}

@media (min-width: 990px){

    .offcanvas-end{
      width: auto;
      position: absolute;
    }

    .navbar-nav {
      margin-top: 35px;
      padding-right: 12px;
    }

}

.bold-text {
  font-weight: bold;
}

.padding-spacing {
  padding-top: 40px;
}

@media (max-width: 770px) {
  .hero-text {
    max-width: 350px;
    margin: 10px;
    width: 100%;
  }

  .hero-text h1 {
    font-size: 42px;
    padding-top: 350px;
  }
}

@media (max-width: 500px) {
  .padding-spacing{
    padding-top: 15px;
  }

  .hero-image{
    height: 450px;
  }

  .hero-text h1 {
    padding-top: 280px;
    font-size: 32px;
  }

  .hero-text {
    max-width: 300px;
    margin: 10px;
    width: 100%;
  }

  .goTop__text{
    position: fixed;
    display: flex;
    justify-content: center;
    padding:6px;
     font-size: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    bottom: 5%;
    left: 85%;
    cursor: pointer;
    text-align: center;
    align-items: center;
  }

  .logo {
    max-height: 100px;
  }

  .navbar-toggler{
    margin-top: 30px;
    float: right;
    border-color: none;
  }
}